<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Carga Seca Autorizaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">
                    Solicitud Carga Seca Autorizaciones
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="listaSitio">Tipo de Solicitud</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.solicitud_type"
                        @change="getIndex()"
                      >
                        <option value="">Seleccione...</option>
                        <option value="1">Solicitudes Internas</option>
                        <option value="2">Solicitudes Nacionales</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!-- SE LLAMAN BOTONES ACA -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                    text-nowrap
                  "
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th colspan="2">
                        # Solicitud
                        <input
                          v-model="filtros.id_solicitud"
                          type="number"
                          class="form-control form-control-sm"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>Tipo Solicitud</th>
                      <th>Tipo Cargo</th>
                      <th>Centro Logístico</th>
                      <th>
                        Tipo Autorización
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_autorizacion"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="autorizacion in listasForms.tipo_autorizaciones"
                            :key="autorizacion.numeracion"
                            :value="autorizacion.numeracion"
                          >
                            {{ autorizacion.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>CECO/AFE (%)</th>
                      <th>Inicio Solicitud</th>
                      <th>Fecha Autorización</th>
                      <th>
                        Usuario
                        <input
                          v-model="filtros.user"
                          type="text"
                          class="form-control form-control-sm"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in autorizaciones.data" :key="item.id">
                      <td>{{ item.id }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.cs_solicitud_type == `App\\CsSolicitudNacional`
                              ? 'bg-info'
                              : item.cs_solicitud_type ==
                                `App\\CsSolicitudInterno`
                              ? 'bg-navy'
                              : 'badge-secondary'
                          "
                        >
                          {{
                            item.cs_solicitud_type == `App\\CsSolicitudNacional`
                              ? "Nacional"
                              : item.cs_solicitud_type ==
                                `App\\CsSolicitudInterna`
                              ? "Interna"
                              : "Sin establecer"
                          }}
                        </span>
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm bg-light"
                          data-toggle="modal"
                          data-target="#modal-form-detalle-tepautorizacion"
                          style="cursor: pointer"
                          v-if="
                            $store.getters.can(
                              'cs.autorizaciones.modal_detalle'
                            )
                          "
                          @click="
                            $refs.CsAutorizacionDetalle.llenar_modal(item)
                          "
                        >
                          {{ item.cs_solicitud_id }}
                        </button>
                        <div v-else>{{ item.cs_solicitud_id }}</div>
                      </td>
                       <td class="text-center">
                        <span
                          class="badge"
                          :class="item.cs_solicitud &&
                            item.cs_solicitud.tipo_solicitud == 1
                              ? 'bg-olive'
                              : item.cs_solicitud && item.cs_solicitud.tipo_solicitud == 2
                              ? 'bg-maroon'
                              : 'badge-secondary'
                          "
                        >
                          {{
                            item.cs_solicitud && item.cs_solicitud.nTipoSolicitud
                              ? item.cs_solicitud.nTipoSolicitud
                              : "Sin definir"
                          }}
                        </span>
                      </td>
                      <td class="text-center">
                        {{
                          item.cs_solicitud_presupuesto
                            ? item.cs_solicitud_presupuesto.nTipoCargo
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          item.cs_solicitud_presupuesto
                            ? item.cs_solicitud_presupuesto.nCentroLogistico
                            : ""
                        }}
                      </td>
                      <td>{{ item.nTipoAutorizacion }}</td>
                      <td class="text-center">
                        {{
                          item.cs_solicitud_presupuesto
                            ? `${item.cs_solicitud_presupuesto.ceco_afe.codigo_contable}`
                            : ""
                        }}
                        <strong>{{
                          item.cs_solicitud_presupuesto
                            ? `(${item.cs_solicitud_presupuesto.porcentaje}%)`
                            : ""
                        }}</strong>
                      </td>
                      <td class="text-center">
                        {{ item.cs_solicitud ? item.cs_solicitud.fecha : "" }}
                      </td>
                      <td>{{ item.fecha_autorizacion }}</td>
                      <td>{{ item.user ? item.user.name : "" }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.estado == 1
                              ? 'badge-success'
                              : item.estado == 2
                              ? 'badge-warning'
                              : item.estado == 3
                              ? 'badge-danger'
                              : 'badge-secondary'
                          "
                        >
                          {{ item.nEstado }}
                        </span>
                      </td>
                      <td class="text-right">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-sm bg-info"
                            data-toggle="modal"
                            data-target="#modal-form-detalle-tepautorizacion"
                            v-if="
                              $store.getters.can(
                                'cs.autorizaciones.modal_detalle'
                              )
                            "
                            @click="
                              $refs.CsAutorizacionDetalle.llenar_modal(item)
                            "
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-olive"
                            v-if="
                              $store.getters.can(
                                'cs.autorizaciones.autorizacion'
                              ) && item.estado == 2
                            "
                            data-toggle="modal"
                            data-target="#modal-form-tep-estado"
                            @click="
                              $refs.CsAutorizacionEstado.llenar_modal_estado(
                                item
                              )
                            "
                          >
                            <i class="fas fa-signature"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="autorizaciones.total">
                  <p>
                    Mostrando del <b>{{ autorizaciones.from }}</b> al
                    <b>{{ autorizaciones.to }}</b> de un total:
                    <b>{{ autorizaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="autorizaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <CsAutorizacionDetalle ref="CsAutorizacionDetalle" />
    <CsAutorizacionEstado ref="CsAutorizacionEstado" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import CsAutorizacionDetalle from "./CsAutorizacionDetalle";
import CsAutorizacionEstado from "./CsAutorizacionEstado";
export default {
  name: "CsAutorizacionIndex",
  components: {
    Loading,
    pagination,
    CsAutorizacionDetalle,
    CsAutorizacionEstado,
  },
  data() {
    return {
      cargando: false,
      filtros: {
        id_solicitud: null,
        tipo_autorizacion: null,
        solicitud_type: null,
        solicitud_type2: null,
        estado: null,
        user: null,
      },
      autorizaciones: {},
      listasForms: {
        tipo_autorizaciones: [],
        estados: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      if (
        this.filtros.solicitud_type != null &&
        this.filtros.solicitud_type != ""
      ) {
        this.cargando = true;
        axios
          .get("/api/cs/Autorizaciones?page=" + page, {
            params: this.filtros,
          })
          .then((response) => {
            this.autorizaciones = response.data;
            this.cargando = false;
          });
      }
    },

    getEstados() {
      axios.get("/api/lista/87").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getTipoAutorizacion() {
      axios.get("/api/lista/86").then((response) => {
        this.listasForms.tipo_autorizaciones = response.data;
      });
    },
    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoAutorizacion();
    // this.getMsg();
  },
};
</script>
